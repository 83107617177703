import '@/shared/plugins/element';
import Vue from 'vue';
import Vuex from 'vuex';
import Router from 'vue-router';
import {
  setupComponentsFiltersDirectivesAndMixins,
  storeAsync,
  routerAsync,
} from '@/app-module';
import app from '@/app.vue';
// import { SettingsService } from '@/modules/settings/settings-service';
// import ProgressBar from '@/shared/progress-bar/progress-bar';
// import { i18n } from '@/i18n';
import PortalVue from 'portal-vue';
import './quasar'

import vueSmoothScroll from 'vue2-smooth-scroll';
import AOS from 'aos';
import 'aos/dist/aos.css'; // You can also use <link> for styles

import BootstrapVue from 'bootstrap-vue'

// import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

import i18n from './vueI18n'
// import 'node_modules/bootstrap/scss/bootstrap';
// import 'node_modules/bootstrap-vue/src/index.scss';
// import 'bootstrap';
// import 'bootstrap/dist/css/bootstrap.min.css';

(async function() {
  // document.title = i18n('app.title');
  // ProgressBar.start();
  // SettingsService.fetchAndApply();
  Vue.directive('click-outside', {
    bind () {
        this.event = event => this.vm.$emit(this.expression, event)
        this.el.addEventListener('click', this.stopProp)
        document.body.addEventListener('click', this.event)
    },   
    unbind() {
      this.el.removeEventListener('click', this.stopProp)
      document.body.removeEventListener('click', this.event)
    },
  
    stopProp(event) { event.stopPropagation() }
  })
  Vue.use(Router);
  Vue.use(PortalVue);
  Vue.config.productionTip = process.env.NODE_ENV === 'production';
  Vue.use(Vuex);
  setupComponentsFiltersDirectivesAndMixins();

  // ======================================= New Installer ======================================= //
  Vue.use(BootstrapVue);
  Vue.use(vueSmoothScroll);
  AOS.init({
    once: false,
  })
  // ============================================================================================= //


  // eslint-disable-next-line
  new Vue({
    store: storeAsync(),
    router: routerAsync(),
    i18n,
    render: (h) => h(app)
  }).$mount('#app');
})();
