import { render, staticRenderFns } from "./layout.vue?vue&type=template&id=47817ad1&scoped=true&"
import script from "./layout.vue?vue&type=script&lang=js&"
export * from "./layout.vue?vue&type=script&lang=js&"
import style0 from "./layout.vue?vue&type=style&index=0&lang=css&"
import style1 from "./layout.vue?vue&type=style&index=1&id=47817ad1&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "47817ad1",
  null
  
)

export default component.exports
import {QLayout,QHeader,QToolbar,QToolbarTitle,QBtn,QDrawer,QScrollArea,QList,QItem,QItemSection,QPageContainer,Ripple} from 'quasar'
component.options.components = Object.assign(Object.create(component.options.components || null), component.options.components || {}, {QLayout,QHeader,QToolbar,QToolbarTitle,QBtn,QDrawer,QScrollArea,QList,QItem,QItemSection,QPageContainer})
component.options.directives = Object.assign(Object.create(component.options.directives || null), component.options.directives || {}, {Ripple})
