//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapActions } from 'vuex';
// import { i18n } from '@/i18n';
import { scroll } from 'quasar';
const { getScrollTarget, setScrollPosition } = scroll;

export default {
  name: 'app-header',

  data() {
    return {
      // tab: 'home',
      drawer: true,
      wid: 0,
      hide: 'visible',
      tab: 'home',
      activeTab: '',
    };
  },
  computed: {
    ...mapGetters({
      currentUserNameOrEmailPrefix:
        'auth/currentUserNameOrEmailPrefix',
      currentUserAvatar: 'auth/currentUserAvatar',
      isMobile: 'layout/isMobile',
      isScreen_md: 'layout/isScreen_md',
      // tab: 'layout/tab',
    }),
  },

  methods: {
    ...mapActions({
      toggleMenu: 'layout/toggleMenu',
      doSignout: 'auth/doSignout',
      navigation: 'layout/navigation',
    }),

    i18n(key, args) {
      return this.$t(key, args);
    },

    handleDropdownCommand(command) {
      if (command === 'doSignout') {
        this.doSignout();
      }

      if (command === 'doEditProfile') {
        this.doEditProfile();
      }
    },

    doEditProfile() {
      return this.$router.push('/auth/edit-profile');
    },

    // this function call when we close the menu with set time out about 300 ms ,
    // the overlay menu disappear cause wid  = 0 that represent the width of overlay menu
    closeNav() {
      this.hide = 'hidden';
      setTimeout(() => {
        this.wid = 0;
      }, 300);
    },

    // this function call when we click on the menu to open it ..
    // just trigger varriable wid that refere to the width of overlay menu
    openNav() {
      this.wid = this.isMobile ? 80 : 50;
      this.hide = 'visible';
    },

    handleScroll(eleId) {
      const ele = document.getElementById(eleId); // You need to get your element here
      const target = getScrollTarget(ele);
      // const offset = ele.offsetTop - ele.scrollHeight
      const offset = ele.offsetTop;
      // const offset = 50
      const duration = 1000;
      setScrollPosition(target, offset, duration);
      this.navigation(eleId);
    },
    scrollHandlerSideMenu(scroll){
        const home = document.getElementById('home')
        const about = document.getElementById('about');
        const features = document.getElementById('features');
        const contact = document.getElementById('contact');
        const homeSide = document.getElementById('homeSide');
        const aboutSide = document.getElementById('aboutSide');
        const featuresSide = document.getElementById('featuresSide');
        const contactSide = document.getElementById('contactSide');

        if (scroll.position >= home.offsetTop && scroll.position < 100) {
          homeSide.classList.add('text-weight-bold');
          aboutSide.classList.remove('text-weight-bold');
          featuresSide.classList.remove('text-weight-bold');
          contactSide.classList.remove('text-weight-bold');

        } 
        else if (
          scroll.position >= about.offsetTop &&
          scroll.position < features.offsetTop - 100
        ) {
          homeSide.classList.remove('text-weight-bold');
          featuresSide.classList.remove('text-weight-bold');
          contactSide.classList.remove('text-weight-bold');
          aboutSide.classList.add('text-weight-bold');
        } 
        else if (
          scroll.position >= features.offsetTop &&
          scroll.position < contact.offsetTop - 800
        ) {
          homeSide.classList.remove('text-weight-bold');
          aboutSide.classList.remove('text-weight-bold');
          contactSide.classList.remove('text-weight-bold');
          featuresSide.classList.add('text-weight-bold');
        } 
        else if(scroll.position > contact.offsetTop - 1000){
          homeSide.classList.remove('text-weight-bold');
          aboutSide.classList.remove('text-weight-bold');
          featuresSide.classList.remove('text-weight-bold');
          contactSide.classList.add('text-weight-bold');
        }
    },
    scrollHandlerSideMenuMobile(scroll){
        // const home = document.getElementById('home')
        const about = document.getElementById('about');
        const features = document.getElementById('features');
        const contact = document.getElementById('contact');
        const homeSide = document.getElementById('homeSide');
        const aboutSide = document.getElementById('aboutSide');
        const featuresSide = document.getElementById('featuresSide');
        const contactSide = document.getElementById('contactSide');

        if (scroll.position >= 10 && scroll.position < 300) {
          homeSide.classList.add('text-weight-bold');
          aboutSide.classList.remove('text-weight-bold');
          featuresSide.classList.remove('text-weight-bold');
          contactSide.classList.remove('text-weight-bold');

        } 
         if (
          scroll.position >= about.offsetTop &&
          scroll.position < features.offsetTop - 100
        ) {
          homeSide.classList.remove('text-weight-bold');
          featuresSide.classList.remove('text-weight-bold');
          contactSide.classList.remove('text-weight-bold');
          aboutSide.classList.add('text-weight-bold');
        } 
        else if (
          scroll.position >= features.offsetTop &&
          scroll.position < contact.offsetTop - 500
        ) {
          homeSide.classList.remove('text-weight-bold');
          aboutSide.classList.remove('text-weight-bold');
          contactSide.classList.remove('text-weight-bold');
          featuresSide.classList.add('text-weight-bold');
        } 
        else if(scroll.position > contact.offsetTop - 500){
          homeSide.classList.remove('text-weight-bold');
          aboutSide.classList.remove('text-weight-bold');
          featuresSide.classList.remove('text-weight-bold');
          contactSide.classList.add('text-weight-bold');
        }
    },
    scrollHandler(scroll) {
      if (this.$route.name == 'home') {
        const about = document.getElementById('about');
        const features = document.getElementById('features');
        const contact = document.getElementById('contact');

        if (!this.isScreen_md) {
          const homeTab = document.getElementById(
            'homeTab'
          );
          const aboutTab = document.getElementById(
            'aboutTab',
          );
          const featuresTab = document.getElementById(
            'featuresTab'
          );
          const contactTab = document.getElementById(
            'contactTab'
          );

          if (
            scroll.position >= 0 &&
            scroll.position < about.offsetTop
          ) {
            this.activeTab = 'home';
            homeTab.classList.remove('q-tab--inactive');
            aboutTab.classList.remove('q-tab--active');
            featuresTab.classList.remove('q-tab--active');
            contactTab.classList.remove('q-tab--active');
            homeTab.classList.add('q-tab--active');
          } else if (
            scroll.position >= about.offsetTop &&
            scroll.position < features.offsetTop
          ) {
            this.activeTab = 'about';

            aboutTab.classList.remove('q-tab--inactive');
            homeTab.classList.remove('q-tab--active');
            featuresTab.classList.remove('q-tab--active');
            contactTab.classList.remove('q-tab--active');
            aboutTab.classList.add('q-tab--active');
          } else if (
            scroll.position >= features.offsetTop &&
            scroll.position <
              contact.offsetTop - contact.scrollHeight - 200
          ) {
            // this.navigation('features')
            this.activeTab = 'features';
            homeTab.classList.remove('q-tab--active');
            aboutTab.classList.remove('q-tab--active');
            contactTab.classList.remove('q-tab--active');
            featuresTab.classList.remove('q-tab--inactive');
            featuresTab.classList.add('q-tab--active');
          } else {
            // this.navigation('contact')
            this.activeTab = 'contact';
            homeTab.classList.remove('q-tab--active');
            aboutTab.classList.remove('q-tab--active');
            featuresTab.classList.remove('q-tab--active');
            contactTab.classList.remove('q-tab--inactive');
            contactTab.classList.add('q-tab--active');
          }
        }
        if(this.isMobile){
          this.scrollHandlerSideMenuMobile(scroll)
          return
        }
        if (this.isScreen_md) {
          this.scrollHandlerSideMenu(scroll)
        
        }
      }
    },
    mounted() {
      this.$refs[this.$route.name].$el.classList.remove(
        'q-hoverable',
      );
      this.$refs[this.$route.name].$el.classList.remove(
        'q-focus-helper',
      );
    },
  },
};
