//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapActions } from 'vuex';
// import firebase from 'firebase/app';
// import isElementInViewport from 'isElementInViewport'
import i18n from '@/vueI18n'

export default {
  name: 'app',

  // created() {
  //   this.doInit();
  //   window.addEventListener('resize', this.handleResize);
  //   this.handleResize();
  // },
  async created() {
    const promises = [
      new Promise(() => this.doInitFirebase()),
      new Promise(() => this.doInit()),
    ];
    Promise.all(promises).then(
    )

    // window.addEventListener('load', this.addToCache(['/']));
    // await this.doInit();
    // firebase.auth().languageCode = localStorage.getItem('language');
    window.addEventListener('resize', this.handleResize);
    // document.addEventListener('scroll', this.handleScroll);
    this.handleResize();
  },
  mounted() {
    // if (localStorage.getItem('language') == "ar") {
    //   import(`quasar/lang/ar`).then((lang) => {
    //     this.$q.lang.set(lang.default);
    //   });
    // } else {
    //   import(`quasar/lang/en-us`).then((lang) => {
    //     this.$q.lang.set(lang.default);
    //   });
    // }
  },
  destroyed() {
    window.removeEventListener('resize', this.handleResize);
    // document.addEventListener('scroll', this.handleScroll);
  },

  methods: {
    ...mapActions({
      doInitFirebase: 'auth/doInitFirebase',
      doInit: 'auth/doInit',
      resize: 'layout/resize',
      navigation: 'layout/navigation',
    }),

    handleResize() {
      this.resize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    },

    // scrollHandler (scroll) {
    //   // const ele = document.getElementById('about')
    //   const home = document.getElementById('home')
    //   const about = document.getElementById('about')
    //   const features = document.getElementById('features')
    //   const contact = document.getElementById('contact')

    //   if (scroll.position >= 0 && scroll.position < about.offsetTop) {
    //     this.navigation('home')
    //   }
    //   else if (scroll.position >= about.offsetTop && scroll.position < features.offsetTop) {
    //     this.navigation('about')
    //   }
    //   else if (scroll.position >= features.offsetTop && scroll.position < contact.offsetTop) {
    //     this.navigation('features')
    //   }
    //   // if (scroll.position <= contact.offsetTop) {
    //   //   this.navigation('contact')
    //   // }
      
    //   // let ids = ['home', 'about', 'features', 'contact']
    //   // ids.find(id => {
    //   //   const el = document.getElementById(id);
    //   //   if(isElementInViewport(el)) {
    //   //     this.navigation(id)
    //   //   }
    //   // });
    // },
    // elementInViewport(el) {
    //   let top = el.offsetTop;
    //   let height = el.offsetHeight;

    //   while(el.offsetParent) {
    //     el = el.offsetParent;
    //     top += el.offsetTop;
    //   }

    //   return (
    //     top >= window.pageYOffset &&
    //     (top + height) <= (window.pageYOffset + window.innerHeight)
    //   );
    // },

    // async addToCache(urls) {
    //   debugger
    //   const myCache = await window.caches.open('my-cache');
    //   await myCache.addAll(urls);
    // }, 

  },
  watch:{
      lang(newVal){
        if(newVal == 'ar') {
          document.getElementsByTagName("html")[0].setAttribute("dir","rtl")
          this.$q.lang.rtl = true
        }
        if(newVal == 'en') {
          document.getElementsByTagName("html")[0].setAttribute("dir","ltr")
          this.$q.lang.rtl = false
        }
      }
  },
  computed: {
    ...mapGetters({
      loadingInit: 'auth/loadingInit',
      tab: 'layout/tab',
    }),
    lang(){
      return i18n.locale
    }
  },
};
