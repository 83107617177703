//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { scroll } from 'quasar';
const { getScrollTarget, setScrollPosition } = scroll;
import { mapGetters } from 'vuex'
export default {
  name: 'app-layout',
    
  data() {
    return {
      drawer:false,
      drawerLeft: false,
      drawerRight: false
    }
  },
  computed:{
    ...mapGetters({
      isScreen_md: 'layout/isScreen_md',
      
    })
  },
  methods:{
    i18n(key, args) {
      return this.$t(key, args);
    },
    closeNav(eleId) {
      this.drawer = !this.drawer
      // setTimeout(() => this.checkRoute(eleId), 1000);
      this.checkRoute(eleId)
      //  setTimeout(() => this.handleScroll(eleId), 500);
    },

    checkRoute(eleId){
      if(this.$router.name != "home"){
        this.$router.push('/')
      }
      // const homeSide = document.getElementById('homeSide')
      // const aboutSide = document.getElementById('aboutSide')
      // const featuresSide = document.getElementById('featuresSide')
      // const contactSide = document.getElementById('contactSide')
      // if(eleId == 'home'){
      //   homeSide.classList.add('text-weight-bold')
      // }
      // if(eleId == 'about'){
      //   aboutSide.classList.add('text-weight-bold')
      // }
      // if(eleId == 'features'){
      //   featuresSide.classList.add('text-weight-bold')
      // }
      // if(eleId == 'contact'){
      //   contactSide.classList.add('text-weight-bold')
      // }
      setTimeout(() => this.handleScroll(eleId), 500);
    },
    handleScroll(eleId) {
      const ele = document.getElementById(eleId); // You need to get your element here
      const target = getScrollTarget(ele);
      const offset = ele.offsetTop-66;
      const duration = 1000;
      setScrollPosition(target, offset, duration);
      // this.navigation(eleId);
      
      
    },
  }
};
