const firebaseConfig = {
  apiKey: "AIzaSyAjzlpO8QLbk-lg8i3kjHZMwthzfDYlISo",
  authDomain: "drug-deal-6e05f.firebaseapp.com",
  databaseURL: "https://drug-deal-6e05f.firebaseio.com",
  projectId: "drug-deal-6e05f",
  storageBucket: "drug-deal-6e05f.appspot.com",
  messagingSenderId: "207604195666",
  appId: "1:207604195666:web:5c783b47bed3a2d60cda8b",
  measurementId: "G-RHPF25JSD6"
};

// Cloud Functions
const backendUrl = `https://europe-west3-${
  firebaseConfig.projectId
}.cloudfunctions.net/api`;
const backendUrlAuth = `https://europe-west3-${
  firebaseConfig.projectId
}.cloudfunctions.net/auth`;
// App Engine
// const backendUrl = `<insert app engine url here>`;

export default {
  firebaseConfig,
  backendUrl,
  backendUrlAuth
};
