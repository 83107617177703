import Layout from '@/modules/layout/components/layout';

const HomePage = () =>
  import('@/modules/home/components/home-page.vue');
const PrivacyPolicyPage = () =>
  import('@/modules/home/components/privacy-policy-page.vue');
const TermsAndConditionsPage = () =>
  import('@/modules/home/components/terms-and-conditions-page.vue');

export default [
  {
    path: '/:lang?',
    exact: true,
    component: Layout,
    children: [
      {
        name: 'home',
        path: '',
        component: HomePage,
        exact: true,
        meta: { auth: true },
      },
      
      {
        name: 'privacy-policy',
        path: 'privacy-policy',
        component: PrivacyPolicyPage,
      },
      {
        name: 'terms-and-conditions',
        path: 'terms-and-conditions',
        component: TermsAndConditionsPage,
      },
    ],
  },
  // {
  //   name: 'privacy-policy',
  //   path: '/:lang?/privacy-policy',
  //   component: PrivacyPolicyPage,
  // },
  // {
  //   name: 'terms-and-conditions',
  //   path: '/:lang?/terms-and-conditions',
  //   component: TermsAndConditionsPage,
  // },
];
