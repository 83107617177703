import Error403Page from '@/modules/layout/components/error-403-page.vue';
import Error404Page from '@/modules/layout/components/error-404-page.vue';
import Error500Page from '@/modules/layout/components/error-500-page.vue';
import UnsubscribeOffersPage from  '@/modules/layout/components/unsubscribe-offers-page.vue';

export default [
  {
    name: 'error403',
    path: '/:lang?/403',
    component: Error403Page,
  },
  {
    name: 'error404',
    path: '/:lang?/404',
    component: Error404Page,
  },
  {
    name: 'error500',
    path: '/:lang?/500',
    component: Error500Page,
  },
  {
    name: 'unsubscribe-offers',
    path: '/:lang?/unsubscribe-offers/:id',
    component: UnsubscribeOffersPage,
  },
];
