import FirebaseRepository from '@/shared/firebase/firebase-repository';
// import firebase from 'firebase'
// import 'firebase/firestore'
import Errors from '@/shared/error/errors';
import { routerAsync } from '@/app-module';
// import Message from '@/shared/message/message';
// import { i18n } from '@/i18n';
// import { IamService } from '@/modules/iam/iam-service';
// import { CompanyProfileService } from '@/modules/company-profile/company-profile-service';

export default {
  namespaced: true,

  state: {
    record: null,
    findLoading: false,
    saveLoading: false,

    imageLoaded: 0,
    runprof_url: 'https://www.runprof.com',
    facebook_url: 'https://www.facebook.com/Drug-Deal-102637918496429',
    instagram_url: 'https://www.instagram.com/drugdealapp/',
    twitter_url: 'https://twitter.com/DrugDealApp',
    // whatsapp_url: 'https://api.whatsapp.com/send?phone=+201050085101',
    // whatsapp_url: 'https://wa.me/201145401795?lang=en',
    whatsapp_url: 'https://wa.me/01060039130?lang=en',
    appStore_url: '',
    googlePlay_url: '',
    drugdeal_email: 'support@drugdealapp.com',
    imagesToPreload: [
      //#region [ this Images is loaded in english & arabic lang only ]
        // 'images/landingHead.png',
        // 'images/landingLogo.svg',
        // '/images/halfFlag.png',
        // '/images/moccupPhone.svg',
      //#endregion
        
      //#region [ this Images is loaded in english lang only ]
        // '/images/slideItemOne.png',
        // '/images/slideItemTwo.png',
        // '/images/slideItemThree.png',
        // '/images/slideItemFour.png',
        // '/images/phones.png',
        // 'images/mobileHead.png',
        // '/images/tourAppStore.png',
        // '/images/tourIphone.png',
        // '/images/appTour.png',
        // '/images/clientAppStore.png',
      //#endregion

      //#region [ this Images is loaded in arabic lang only ]
        // '/images/slideItemOneAr.png',
        // '/images/slideItemTwoAr.png',
        // '/images/slideItemThreeAr.png',
        // '/images/slideItemFourAr.png',
        // 'images/phoneArabicUp.png',
        // '/images/mobileArabicRTL.png',
        // '/images/tourAppStoreAr.png',
        // '/images/tourIphoneAr.png',
        // '/images/appTourAr.png',
        // '/images/clientAppStoreAr.png',
      //#endregion

      // '/images/sarOpaciBg.svg',
      // '/images/footerBg.jpg',
      // '/images/bgSaryahApp.png',
    ],
  },

  getters: {
    record: (state) => state.record,
    findLoading: (state) => !!state.findLoading,
    saveLoading: (state) => state.saveLoading,
    collectionName: () => 'policies',

    isImagesLoaded: (state) => state.imageLoaded == (state.imagesToPreload.length - 10 ),
    imageLoaded: (state) => state.imageLoaded,
    imagesToPreload: (state) => state.imagesToPreload,

    runprof_url: (state) => state.runprof_url,
    facebook_url: (state) => state.facebook_url,
    instagram_url: (state) => state.instagram_url,
    twitter_url: (state) => state.twitter_url,
    whatsapp_url: (state) => state.whatsapp_url,
    appStore_url: (state) => state.appStore_url,
    googlePlay_url: (state) => state.googlePlay_url,
    drugdeal_email: (state) => state.drugdeal_email,
  },

  mutations: {
    RESET(state) {
      state.imageLoaded = 0;
      state.isImagesLoaded = false;
      state.findLoading = false;
      state.saveLoading = false;
      state.record = null;
    },
    CONFIRM_IMAGE_LOADED(state) {
      state.imageLoaded += 1;
    },

    FIND_STARTED(state) {
      state.record = null;
      state.findLoading = true;
    },

    FIND_SUCCESS(state, payload) {
      state.record = payload;
      state.findLoading = false;
    },

    FIND_ERROR(state) {
      state.record = null;
      state.findLoading = false;
    },
  },

  actions: {
    onImgLoad({ commit }) {
      commit('CONFIRM_IMAGE_LOADED');
    },
    async doFind({ commit, getters}, id) {
      try {
        commit('FIND_STARTED');
        // debugger
        const record = await FirebaseRepository.findDocument(getters.collectionName, id)
        commit('FIND_SUCCESS', record);
      } catch (error) {
        Errors.handle(error);
        commit('FIND_ERROR');
        routerAsync().push('/');
      }
    },
  },
};
