//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions, mapGetters } from 'vuex';
import firebase from 'firebase/app';
import 'firebase/firestore';   // for cloud firestore

export default {
  data(){
    return {
      uid: ''
    }
  },
  computed:{
    ...mapGetters({
      isScreen_md: 'layout/isScreen_md'
    })
  },
  methods:{
    ...mapActions({
      doUpdate: 'layout/doUpdate',
      deleteUserFromAthTable: 'layout/deleteUserFromAthTable'
    }),
    async removeAnon(){
      // var user = firebase.auth().currentUser;
     await this.deleteUserFromAthTable(this.uid)


      // var user = firebase.auth().getUser(this.uid);
      // user.delete().then(function() {
      //   // User deleted.
      // }).catch(function(error) {
      //   // An error happened.
      // });



      // debugger
      // firebase
      // .auth()
      // .deleteUser(this.uid)
      // .then(() => {
      //   debugger
      // })
      // .catch((error) => {
      // });
    }
  },
  async created(){
    if(!firebase.auth().currentUser){
      firebase.auth().signInAnonymously().then(async () => {
        firebase.auth().onAuthStateChanged((user) => {
        if (user) {
          this.uid = user.uid;
        } 
      });
        const doc = await firebase.firestore().collection('user').doc(this.$route.params.id).get()
        let user = doc.data()
        user.offerSubscribed = false
        
        await firebase.firestore().collection('user').doc(this.$route.params.id).update(user)        
        await this.deleteUserFromAthTable(this.uid)
      })
    }
  },
}
