import service from '@/modules/auth/auth-service';
import Message from '@/shared/message/message';
import { i18n } from '@/i18n';
import Errors from '@/shared/error/errors';
import { routerAsync } from '@/app-module';
import ProgressBar from '@/shared/progress-bar/progress-bar';
import firebase from 'firebase/app';
// import { IamService } from '@/modules/iam/iam-service'; 

export default {
  namespaced: true,

  state: {
    authenticationUser: null,
    currentUser: null,
    loadingInit: true,
    loadingEmailConfirmation: false,
    loadingPasswordResetEmail: false,
    loadingUpdateProfile: false,
    loading: false,
    loadingVerify: false,
    firstTime: true,
    confirmationResult: null,
  },

  getters: {
    confirmationResult: (state) => state.confirmationResult,
    authenticationUser: (state) => state.authenticationUser,
    currentUser: (state) => state.currentUser,
    currentUserEmail: (state, getters) =>
      getters.currentUser
        ? getters.currentUser.email
        : null,
    currentUserFullName: (state, getters) =>
      getters.currentUser
        ? getters.currentUser.fullName
        : '',

    signedIn: (state, getters) =>
      !!getters.currentUser && !!getters.currentUser.id,

    roles: (state, getters) =>
      getters.currentUser
        ? getters.currentUser.roles || []
        : [],

    completeRegistration: (state, getters) =>
      getters.currentUser
        ? !!getters.currentUser.phoneNumber &&
          !!getters.currentUser.idNumber &&
          !!getters.currentUser.userType &&
          !!getters.currentUser.userCategory  
        : false,

    emptyPermissions: (state, getters) =>
      !getters.roles || !getters.roles.length,

    loading: (state) => !!state.loading,
    loadingVerify: (state) => !!state.loadingVerify,
    firstTime: (state) => !!state.firstTime,
    loadingInit: (state) => !!state.loadingInit,

    loadingEmailConfirmation: (state) =>
      !!state.loadingEmailConfirmation,

    loadingPasswordResetEmail: (state) =>
      !!state.loadingPasswordResetEmail,

    loadingUpdateProfile: (state) =>
      !!state.loadingUpdateProfile,

    currentUserNameOrEmailPrefix: (state, getters) => {
      if (!getters.currentUser) {
        return null;
      }

      if (
        getters.currentUserFullName &&
        getters.currentUserFullName.length < 25
      ) {
        return getters.currentUserFullName;
      }

      if (getters.currentUser.firstName) {
        return getters.currentUser.firstName;
      }

      return getters.currentUser.email.split('@')[0];
    },

    currentUserAvatar: (state, getters) => {
      if (
        !getters.currentUser ||
        !getters.currentUser.avatars ||
        !getters.currentUser.avatars.length ||
        !getters.currentUser.avatars[0].publicUrl
      ) {
        return null;
      }

      return getters.currentUser.avatars[0].publicUrl;
    },
  },

  mutations: {
    CURRENT_USER_REFRESH_SUCCESS(state, payload) {
      state.currentUser = payload.currentUser || null;
    },
    VERIFY_START(state) {
      state.loadingVerify = true;
    },
    VERIFY_SUCCESS(state) {
      state.loadingVerify = false;
    },
    VERIFY_ERROR(state) {
      state.loadingVerify = false;
    },

    VERIFICATION_CODE_START(state) {
      state.loading = true;
    },
    VERIFICATION_CODE_SUCCESS(state, payload) {
      state.confirmationResult = payload.confirmationResult || null;
      state.loading = false;
    },
    VERIFICATION_CODE_ERROR(state) {
      state.confirmationResult = null;
      state.loading = false;
    },

    AUTH_START(state) {
      state.loading = true;
    },
    AUTH_SUCCESS(state, payload) {
      state.authenticationUser =
        payload.authenticationUser || null;
      state.currentUser = payload.currentUser || null;
      state.loading = false;
    },
    AUTH_ERROR(state) {
      state.authenticationUser = null;
      state.currentUser = null;
      state.loading = false;
    },

    EMAIL_CONFIRMATION_START(state) {
      state.loadingEmailConfirmation = true;
    },

    EMAIL_CONFIRMATION_SUCCESS(state) {
      state.loadingEmailConfirmation = false;
    },

    EMAIL_CONFIRMATION_ERROR(state) {
      state.loadingEmailConfirmation = false;
    },

    PASSWORD_RESET_START(state) {
      state.loadingPasswordResetEmail = true;
    },

    PASSWORD_RESET_SUCCESS(state) {
      state.loadingPasswordResetEmail = false;
    },

    PASSWORD_RESET_ERROR(state) {
      state.loadingPasswordResetEmail = false;
    },

    UPDATE_PROFILE_START(state) {
      state.loadingUpdateProfile = true;
    },

    UPDATE_PROFILE_SUCCESS(state) {
      state.loadingUpdateProfile = false;
    },

    UPDATE_PROFILE_ERROR(state) {
      state.loadingUpdateProfile = false;
    },

    AUTH_INIT_SUCCESS(state, payload) {
      state.authenticationUser =
        payload.authenticationUser || null;
      state.currentUser = payload.currentUser || null;
      state.loadingInit = false;
    },

    AUTH_INIT_ERROR(state) {
      state.authenticationUser = null;
      state.currentUser = null;
      state.loadingInit = false;
    },

    AUTH_NOT_FOUND_USER(state) {
      state.authenticationUser = null;
      state.currentUser = null;
      state.loadingInit = false;
    },

    STOP_SPLASH_SCREEN(state) {
      state.firstTime = false;
    },
  },

  actions: {
//#region [ Init ]
    doStopSplash({ commit }) {
      commit('STOP_SPLASH_SCREEN');
    },
    async doInitFirebase() {
      await service.init();
    },
    async doInit({ commit, dispatch }) {
      // await service.init();
      let currentUser = localStorage.getItem('currentUser');
      if (!currentUser) {
        commit('AUTH_INIT_SUCCESS', {
          currentUser: null,
          authenticationUser: null,
        });
        ProgressBar.done();
        return;
      }
      
      const unsubscribe = service.onAuthStateChanged(
        (authenticationUser) => {
          dispatch('doSigninFromAuthChange', authenticationUser);
          unsubscribe();
        },
        () => {
          // console.error(error);
          commit('AUTH_INIT_ERROR');
        },
      );
    },
    async doWaitUntilInit({ getters }) {
      if (!getters.loadingInit) {
        return Promise.resolve();
      }

      return new Promise((resolve) => {
        const waitUntilInitInterval = setInterval(() => {
          if (!getters.loadingInit) {
            clearInterval(waitUntilInitInterval);
            resolve();
          }
        }, 500);
      });
    },
//#endregion

//#region [ Send Emails ]
    async doSendWelcomeEmail({ getters }) {
      try {
        // commit('EMAIL_CONFIRMATION_START');
        // debugger
        await service.sendWelcomeEmail(getters.currentUser.email, getters.currentUser.firstName);
        // Message.success(
        //   i18n('auth.verificationEmailSuccess'),
        // );
        // commit('EMAIL_CONFIRMATION_SUCCESS');
      } catch (error) {
        Errors.handle(error);
        // commit('EMAIL_CONFIRMATION_ERROR');
      }
    },
    
    async doSendEmailConfirmation({ commit, getters }) {
      try {
        commit('EMAIL_CONFIRMATION_START');
        // debugger

        await service.sendEmailVerification(
          getters.authenticationUser,
        );

        Message.success(
          i18n('auth.verificationEmailSuccess'),
        );

        commit('EMAIL_CONFIRMATION_SUCCESS');
      } catch (error) {
        Errors.handle(error);
        commit('EMAIL_CONFIRMATION_ERROR');
      }
    },

    async doSendPasswordResetEmail({ commit }, email) {
      try {
        commit('PASSWORD_RESET_START');
        await service.sendPasswordResetEmail(email);
        Message.success(i18n('auth.passwordResetEmailSuccess'));
        commit('PASSWORD_RESET_SUCCESS');
      } catch (error) {
        Errors.handle(error);
        commit('PASSWORD_RESET_ERROR');
      }
    },
//#endregion

//#region [ Authentication With Social ]
    async doSigninSocial({ commit }, { provider, rememberMe }) {
      try {
        commit('AUTH_START');

        let authenticationUser = null;
        let currentUser = null;

        const credentials = await service.signinWithSocial(
          provider,
          rememberMe,
        );

        // debugger;
        if (credentials && credentials.user) {
          authenticationUser = credentials.user;
          currentUser = await service.fetchMe();
          currentUser.emailVerified = authenticationUser.emailVerified;

          if (!currentUser.emailVerified) {
            service.sendEmailVerification(
              authenticationUser,
            );
          }
          // ======================================================================================================== //
          //                               IF --> user is new and sign in by Social                                   //
          //                                 THEN-- > get profile info from social                                    //
          // ======================================================================================================== //
          if (credentials.additionalUserInfo.isNewUser) {
            let firstName;
            let lastName;
            let phoneNumber;
            let avatar = [];
            if (provider == 'google') {
              firstName = credentials.additionalUserInfo.profile.given_name;
              lastName = credentials.additionalUserInfo.profile.family_name;
              phoneNumber = credentials.user.phoneNumber;
              if (credentials.user.photoURL) {
                avatar = [
                  {
                    id: '',
                    name: 'photo.jpg',
                    privateUrl: '',
                    publicUrl: credentials.additionalUserInfo.profile.picture,
                  },
                ];
              }
            } else if (provider == 'facebook') {
              firstName = credentials.additionalUserInfo.profile.first_name;
              lastName = credentials.additionalUserInfo.profile.last_name;
              phoneNumber = credentials.user.phoneNumber;
              if (credentials.additionalUserInfo.profile.picture.data.url) {
                avatar = [
                  {
                    id: '',
                    name: 'photo.jpg',
                    privateUrl: '',
                    publicUrl: credentials.additionalUserInfo.profile.picture.data.url,
                  },
                ];
              }
            }
            await service.updateProfile(
              firstName,
              lastName,
              phoneNumber,
              avatar,
            );
            currentUser.avatars = avatar;
          }
          // ======================================================================================================== //
        }

        // in background
        service.reauthenticateWithStorageToken();

        commit('AUTH_SUCCESS', {
          currentUser,
          authenticationUser,
        });
        localStorage.setItem(
          'currentUser',
          service.encryption(currentUser, 'secret-c-u'),
        );
        firebase.auth().languageCode = localStorage.getItem(
          'language',
        );
        routerAsync().push('/');
      } catch (error) {
        await service.signout();
        Errors.handle(error);
        commit('AUTH_ERROR');
      }
    },
//#endregion


//#region [ Authentication With Email and Password ]
    async doRegisterEmailAndPassword(
      { commit },
      { email, password },
    ) {
      try {
        commit('AUTH_START');
        // debugger
        const authenticationUser = await service.registerWithEmailAndPassword(
          email,
          password,
        );
        const currentUser = await service.fetchMe();
        currentUser.emailVerified = authenticationUser.emailVerified;

        // in background
        service.reauthenticateWithStorageToken();

        commit('AUTH_SUCCESS', {
          currentUser,
          authenticationUser,
        });

        localStorage.setItem(
          'currentUser',
          service.encryption(currentUser, 'secret-c-u'),
        );
        routerAsync().push('/');
      } catch (error) {
        await service.signout();
        Errors.handle(error);
        commit('AUTH_ERROR');
      }
    },

    async doSigninWithEmailAndPassword({ commit }, { email, password, rememberMe }) {
      try {
        commit('AUTH_START');
       
        let authenticationUser = null;
        let currentUser = null;

        const credentials = await service.signinWithEmailAndPassword(
          email,
          password,
          rememberMe,
        );

        if (credentials && credentials.user) {
          authenticationUser = credentials.user;
          currentUser = await service.fetchMe();
          currentUser.emailVerified =
            authenticationUser.emailVerified;
        }

        // in background
        service.reauthenticateWithStorageToken();

        commit('AUTH_SUCCESS', {
          currentUser,
          authenticationUser,
        });

        localStorage.setItem(
          'currentUser',
          service.encryption(currentUser, 'secret-c-u'),
        );
        routerAsync().push('/');
      } catch (error) {
        await service.signout();
        Errors.handle(error);
        commit('AUTH_ERROR');
      }
    },

    async doEmailVerified({ commit }) {
      try {
        // debugger;
        // await service.checkEmailVerified();
        commit('VERIFY_START');
        await firebase.auth().currentUser.reload();
        await firebase.auth().onAuthStateChanged((authenticationUser) => {
          if (authenticationUser.emailVerified) {
            commit('VERIFY_SUCCESS');
            let currentUser = localStorage.getItem('currentUser');
            if (currentUser) {
              let user = service.decryption(currentUser, 'secret-c-u');
              user.emailVerified = authenticationUser.emailVerified;
              localStorage.setItem('currentUser', service.encryption(user, 'secret-c-u'));
            }
            document.location.reload(false);
          } else {
            commit('VERIFY_SUCCESS');
            Message.error(i18n('Email not verified yet'));
          }
        });
      } catch (error) {
        service.signout();
        Errors.handle(error);
        commit('VERIFY_ERROR');
      }
    },
//#endregion

//#region [ Authentication With Phone Number ]
    async doSigninWithPhoneNumberCredential({ commit }, { verificationId, code, rememberMe }) {
      try {
        commit('AUTH_START');
        let currentUser;
        let authenticationUser;
        //#region [ Set Authentication State Persistence ]
        const persistence = rememberMe
          ? firebase.auth.Auth.Persistence.LOCAL
          : firebase.auth.Auth.Persistence.SESSION;    
        
        await firebase.auth().setPersistence(persistence);
        //#endregion
        
        let credential = await firebase.auth.PhoneAuthProvider.credential(verificationId, code)
        const credentials = await firebase.auth().signInWithCredential(credential);
        
        if (credentials.additionalUserInfo.isNewUser) {
          await firebase.auth().currentUser.delete().then(() => {
          }).catch((error) => {
            throw error
          });
          commit('AUTH_SUCCESS', {
            currentUser,
            authenticationUser,
          });
          Errors.handle({code: 'auth/user-not-found'});
          routerAsync().push('/auth/signin');
          return
        } 

        if (credentials && credentials.user) {
          authenticationUser = credentials.user;
          currentUser = await service.fetchMe();
          currentUser.emailVerified = authenticationUser.emailVerified;
        }

        // in background
        service.reauthenticateWithStorageToken();
        commit('AUTH_SUCCESS', {
          currentUser,
          authenticationUser,
        });

        localStorage.setItem('currentUser', service.encryption(currentUser, 'secret-c-u'));
        routerAsync().push('/auth/update-account-roles');
        // routerAsync().push('/');
      } catch (error) {
        await service.signout();
        Errors.handle(error);
        commit('AUTH_ERROR');
      }
    },

    async doSendVerificationCodeToUserPhone({ commit }, { phoneNumber, appVerifier }) {
      try {
        commit('VERIFICATION_CODE_START');
        await firebase.auth().signInWithPhoneNumber(phoneNumber, appVerifier)
        .then((confirmationResult) => {  
          window.confirmationResult = confirmationResult;
        }).catch((error) => {
          throw error
        });

        let confirmationResult = window.confirmationResult
        commit('VERIFICATION_CODE_SUCCESS', {
          confirmationResult,
        });
        return true;
      } catch (error) {
        await service.signout();
        Errors.handle(error);
        commit('VERIFICATION_CODE_ERROR');
        return false;
      }
    },
//#endregion


    async doSignout({ commit, dispatch }) {
      try {
        commit('AUTH_START');
        await service.signout();
        // localStorage.clear();
        localStorage.removeItem('currentUser');
        localStorage.removeItem('token');
        commit('AUTH_SUCCESS', {
          authenticationUser: null,
          currentUser: null,
        });
        dispatch('doStopSplash');
        routerAsync().push('/auth/signin');
      } catch (error) {
        Errors.handle(error);
        commit('AUTH_ERROR');
      }
    },

    async doSigninFromAuthChange({ commit }, authenticationUser) {
      try {
        let currentUser = localStorage.getItem('currentUser');
        if (authenticationUser) {
          if (!currentUser) {
            currentUser = await service.fetchMe();
            localStorage.setItem(
              'currentUser',
              service.encryption(currentUser, 'secret-c-u'),
            );
          } else {
            currentUser = service.decryption(
              currentUser,
              'secret-c-u',
            );
          }

          // in background
          // service.reauthenticateWithStorageToken();
          currentUser.emailVerified = authenticationUser.emailVerified;
        }

        commit('AUTH_INIT_SUCCESS', {
          currentUser,
          authenticationUser: authenticationUser,
        });
        ProgressBar.done();
      } catch (error) {
        service.signout();
        Errors.handle(error);
        commit('AUTH_INIT_ERROR', error);
        ProgressBar.done();
      }
    },

    async doRefreshCurrentUser({ commit, getters }) {
      try {
        const authenticationUser = getters.authenticationUser;
        const currentUser = await service.fetchMe();
        currentUser.emailVerified = authenticationUser.emailVerified;
        
        //#region [ refresh CurrentUser in localStorage ]
        localStorage.setItem(
          'currentUser',
          service.encryption(currentUser, 'secret-c-u'),
        );
        //#endregion

        // in background
        service.reauthenticateWithStorageToken();

        commit('CURRENT_USER_REFRESH_SUCCESS', {
          currentUser,
        });
      } catch (error) {
        service.signout();
        Errors.handle(error);

        commit('CURRENT_USER_REFRESH_ERROR', error);
      }
    },

    async doUpdateProfile(
      { commit, dispatch },
      { firstName, lastName, phoneNumber, avatars },
    ) {
      try {
        commit('UPDATE_PROFILE_START');

        await service.updateProfile(
          firstName,
          lastName,
          phoneNumber,
          avatars,
        );

        commit('UPDATE_PROFILE_SUCCESS');
        await dispatch('doRefreshCurrentUser');
        Message.success(i18n('auth.profile.success'));
        routerAsync().push('/');
      } catch (error) {
        Errors.handle(error);
        commit('UPDATE_PROFILE_ERROR');
      }
    },

//#region [ Local Storage Functions ]
    doUpdateCurrentUserInLocalStorage( { commit }, currentUser) {
      // debugger;
      if (currentUser) {
        localStorage.setItem(
          'currentUser',
          service.encryption(currentUser, 'secret-c-u'),
        );
        commit('CURRENT_USER_REFRESH_SUCCESS', {
          currentUser,
        });
      }
    },
//#endregion
  },
};
